header {
    height: 100vh;
    padding-top: 5rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ------CTA--------- */
.cta {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2.5rem
}

/* ------Header Socials--------- */
.header__socials {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    position: absolute;
    bottom: 3rem;
    left: 0rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ------Header Image--------- */
.me {
    background: radial-gradient(var(--color-primary), transparent);
    width: 14rem;
    position: absolute;
    left: calc(50% - 7rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    clip-path: border-box;
}

/* ------Header Scroll--------- */
.scroll__down {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 5rem;
    transform: rotate(90deg);
    font-size: 0.9rem;
}

/* ------Media Queries Medium Devices--------- */
@media screen and (max-width:600px) {
    header {
        height: 100vh;
    }

    .scroll__down {
        display: none;
    }
}