.experience__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.experience__container h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);

}

.experience__icon {
    color: var(--color-primary);
    margin-top: 6px;
}

.experience__frontend,
.experience__backend {
    padding: 2.5rem 5rem;
    background: var(--color-bg-variant);
    border: 1px solid var(--color-bg-variant);
    transition: var(--transition);
    border-radius: 3rem;
}

.experience__frontend:hover,
.experience__backend:hover {
    background: var(--color-bg);

}

.experience__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1rem;
}

.experience__details {
    display: flex;
    gap: 1rem;
}

/* ------Media Queries Medium Devices--------- */
@media screen and (max-width:1024px) {
    .experience__container {
        grid-template-columns: 1fr;
    }

    .experience__frontend,
    .experience__backend {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .experience__content {
        padding: 1rem;
    }
}

/* ------Media Queries Small Devices--------- */
@media screen and (max-width:600px) {
    .experience__container {
        gap: 1rem;
    }

    .experience__frontend,
    .experience__backend {
        width: 100%;
        padding: 2rem 1rem;
    }

    .experience__content {}
}