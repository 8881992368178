#about{
    margin-top: 0;
}
.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about__me {
    width: 100%;
    border-radius: 1rem;
    background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
    display: grid;
    place-items: center;
    height: 34rem;
    margin: 25% 0;
}

.about__me-img {
    border-radius: 1rem;
    overflow: hidden;
    transform: rotate(-5deg);

}

.about__me-img:hover {
    transform: rotate(5deg);
    transition: var(--transition);
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.4rem;
}

.about__card {
    background: var(--color-bg-variant);
    border-radius: 1rem;
    border: 1px solid transparent;
    padding: 1rem;
    text-align: center;
    transition: var(--transition);
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-primary);
    cursor: default;
}

.about__icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about__card h5 {
    font-size: 0.95rem;
}

.about__card small {
    font-size: 0.8rem;
    color: var(--color-light);
}

.about__content p {
    margin: 2rem 0 2.6rem;
}

/* ------Media Queries Medium Devices--------- */
@media screen and (max-width:1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about__me {
        width: 60%;
        margin: 2rem auto 4rem;
    }

    .about__content p {
        margin: 1rem 0 1.5rem;
        text-align: center;
    }

    .about__content .btn {
        width: 90%;
        margin: 0 5%;
        text-align: center;
    }
}

/* ------Media Queries Small Devices--------- */
@media screen and (max-width:600px) {
    .about__me {
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about__content p {
        margin: 1rem 0 1.5rem;

    }

    .about__cards {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
}