.nav {
    display: flex;
    position: fixed;
    bottom: 2rem;
    transform: translate(-50%);
    left: 50%;
    font-size: 24px;
    gap: 1rem;
    padding: 0.3rem 1.2rem;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3rem;
    z-index: 2;
}

nav a {
    background: transparent;
    padding: 0.5rem;
    color: var(--color-light);
    display: flex;
    border-radius: 50%;
    font-size: 1.2rem;
}

nav a:hover {
    background-color: rgba(0, 0, 0, 0.3);
    color: var(--color-primary);
}

nav a.active {
    background: var(--color-primary);
    color: var(--color-white);
}