.experience {
    text-align: center;
    margin-top: 3rem;
}

.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}

.timeline:after {
    content: "";
    position: absolute;
    width: 6px;
    background-color: var(--color-bg-variant);
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}

.containertime {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
}

.containertime:after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: var(--color-white);
    border: 4px solid var(--color-primary);
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}

.left {
    left: 0;
}

.left:before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid var(--color-bg-variant);
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent var(--color-bg-variant);
}

.right {
    left: 50%;
}

.right:before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid var(--color-bg-variant);
    border-width: 10px 10px 10px 0;
    border-color: transparent var(--color-bg-variant) transparent transparent;
}

.right::after {
    left: -16px;
}

.content {
    padding: 20px 30px;
    background-color: var(--color-bg-variant);
    position: relative;
    border-radius: 1rem;
}

.companyLink {
    padding-top: 1rem;
}

h4,
h2 {
    color: var(--color-primary);
    margin-bottom: 1rem;
}

.jobLevel {
    color: var(--color-light);

}

/* ------Media Queries Small Devices--------- */
@media screen and (max-width: 900px) {
    .timeline::after {
        left: 2rem;
    }

    .containertime {
        width: 100%;
        padding-left: 3rem;
        padding-right: 2rem;
    }

    .containertime::before {
        left: 3rem;
        border-width: 10px 10px 10px 0;
    }

    .left::after,
    .right::after {
        left: 1.1rem;
    }

    .right {
        left: 0%;
    }
}