.contact__container {
    display: grid;
    grid-template-columns: 30% 70%;
}

.contact__option {
    background: var(--color-bg-variant);
    width: fit-content;
    padding: 1.5rem 2rem;
    border-radius: 2rem;
    margin-bottom: 2rem;
}

a {
    color: var(--color-primary);
    transition: var(--transition);

}

a:hover {
    color: var(--color-white);
}


.contact__option:hover {
    background: transparent;
    border: 1px solid var(--color-primary);
}

.contact__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

.contact__form-input {
    padding: 1rem;
    background-color: transparent;
    border: 2px solid var(--color-bg-variant);
    border-radius: 0.7rem;
    color: var(--color-white);
    font-size: 16px;

}

.contact__form-input:hover {
    border-color: var(--color-primary);
}

textarea {
    resize: none;
}

#confirmation {
    display: none;
}

/* ------Media Queries Medium Devices--------- */
@media screen and (max-width:1024px) {
    .contact__options {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .contact__option {
        width: auto;
    }
}

/* ------Media Queries Small Devices--------- */
@media screen and (max-width:600px) {
    .contact__options {
        grid-template-columns: 1fr;
    }
}